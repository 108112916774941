header h1{
  border: 0;
  margin: 0;
  padding: 0;
  text-align: center;
}

header nav ul{
  list-style: none;
  display: block;
  height: 1.3em;
  border: 1px solid black;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  color: white;
}

header nav ul li{
  float: left;
}

header nav ul li a{
  color: white;
  text-decoration: none;
  margin: 0;
  padding: 0 1em;
  height: 1.5em;
}

header nav ul li a.active,
header nav ul li a:hover{
  color: black;
  background-color: silver;
}
