html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  height: 100%;
}

.map-marker {
  border: 1.5px solid #fffaf0b5;
  border-radius: 50%;
  background-color: rgba(130, 191, 255, 0.66);
}

.tooltip {
  background-color: #222222cf;
  border-color: #222;
  color: #fff;
  display: inline-block;
  z-index: 99;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
  cursor: default;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.checklist {
  padding: 0;
  margin: 0;
}

.checklist li {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.float-right {
  float: right;
}

.hover {
  position: relative;
}
.hover:hover .hover__no-hover {
  opacity: 0;
}
.hover:hover .hover__hover {
  opacity: 1;
}
.hover__hover {
  position: absolute;
  top: 0;
  opacity: 0;
}
.hover__no-hover {
  opacity: 1;
}
