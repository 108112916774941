table {
  border: 0px solid #000000;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
table td, table th {
  border: 1px solid #000000;
  padding: 5px 4px;
}
table thead {
  background: #CFCFCF;
  border-bottom: 1px solid #000000;
}
table thead th {
  font-weight: bold;
  color: #000000;
  text-align: left;
}
table tfoot {
  font-weight: bold;
  color: #000000;
  border-top: 1px solid #000000;
}
