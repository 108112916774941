.content{
  flex: 1 0 auto;
}

.page{
  text-align: left;
}

.page h1{
  margin: 0;
  padding: 0;
  background-color: silver;
  color: black;
  border-bottom: 1px solid black;
  font-size: 1.5em;
  font-style: italic;
  font-weight: bold;
}
