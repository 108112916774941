footer{
  height: 2em;
  font-size: 0.75em;
  color: #999;
  background-color: #333;
  flex-shrink: 0;
}

footer table{
  width: 100%;
}
